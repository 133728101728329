<template>
	<div id="app">
		<div class="alert alert-info text-center m-2" v-if="motd != null && hide_motd == false">
			<i class="fa-regular fa-info-circle"></i> {{motd}}
			<a href="" @click.prevent="hide_motd = true" class="motd-x"><i class="fa-regular fa-times-circle"></i></a>
		</div>
		<div class="alert alert-warning text-center m-2" v-if="logged_in && server_version != '' && version != '' && server_version != version">
			<i class="fa-regular fa-info-circle"></i> Goliath v{{server_version}} is now available. You are using v{{version}}. Please reload the page to upgrade.
		</div>
		<div class="alert alert-danger text-center m-2" v-if="logged_in && user.account_id != user.active_account_id">
			<i class="fa-regular fa-exclamation-circle"></i> You are acting on an account other than your own. <b>DO NOT</b> use multiple tabs or multiple browsers.
		</div>

		<header class="container-fluid px-0 mb-3">
			<nav class="navbar navbar-light px-3">
				<div>
					<a href="" class="navbar-brand" @click.prevent="toggleMenu()" v-if="logged_in"><i class="fa-regular fa-lg fa-bars"></i></a>
					<a href="" class="navbar-brand" @click.prevent="toggleMenu()"><img :src="api_server + '/assets/logo/' + user.active_account_id" id="logo" v-if="account" /></a>
					<div class="loading-container">
						<span v-if="loading_count > 0"><div class="spinner-border spinner-border-sm text-primary" role="status"><span class="sr-only">Loading...</span></div></span>
					</div>
				</div>
				<ul class="nav pb-2" v-if="logged_in && userHasAction(user, ['FEATURE_LEADS'])">
					<li class="nav-item">
						<span class="btn-status mx-1" :class="{'on': socket != null && socket.connected && user.socket_id != null && user.receiving_leads, 'off': socket != null && socket.connected && user.socket_id != null && !user.receiving_leads, 'idle': socket != null && socket.connected && user.socket_id != null && user.idle && user.receiving_leads, 'off-danger': socket == null || socket.disconnected || user.socket_id == null}">
							<a href="#" @click.prevent="toggleField('receiving_leads')" class="span1 py-1 px-2"><i class="fa-regular fa-fw fa-user-plus"></i></a>
							<a href="#" @click.prevent="toggleField('receiving_leads')" class="span2 py-1 px-2"><span v-if="!user.receiving_leads">Not </span>Receiving Leads</a>
						</span>
					</li>
					<li class="nav-item">
						<span class="btn-status mx-1" :class="{'on': socket != null && socket.connected && user.socket_id != null && user.available, 'off': socket != null && socket.connected && user.socket_id != null && !user.available, 'idle': socket != null && socket.connected && user.socket_id != null && user.idle && user.available, 'off-danger': socket == null || socket.disconnected || user.socket_id == null}">
							<a href="#" @click.prevent="toggleField('available')" class="span1 py-1 px-2"><i class="fa-regular fa-fw fa-phone-volume"></i></a>
							<a href="#" @click.prevent="toggleField('available')" class="span2 py-1 px-2" v-if="user.available">Available</a>
							<a href="#" @click.prevent="toggleField('available')" class="span2 py-1 px-2" v-else-if="!user.available">Busy</a>
						</span>
					</li>
					<li class="nav-item">
						<span class="btn-status mx-1" :class="{'on': socket != null && socket.connected && user.socket_id != null && drip_running, 'off-danger': socket == null || socket.disconnected || user.socket_id == null || !drip_running}" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])" title="Contact Strategies">
							<router-link to="/csqueue" class="span1 py-1 px-2"><i class="fa-regular fa-fw fa-comment-dollar"></i></router-link>
							<router-link to="/csqueue" class="span2 py-1 px-2"><i class="fa-regular fa-fw" :class="{'fa-thumbs-up': socket != null && socket.connected && user.socket_id != null && drip_running, 'fa-thumbs-down': socket == null || socket.disconnected || user.socket_id == null || !drip_running}"></i></router-link>
						</span>
					</li>
					<li class="nav-item"><a href="#" class="nav-link">&nbsp;<!--for vertical alignment with other menu--></a></li>
				</ul>
				<ul class="nav" @click="closeMenu()" v-if="logged_in">
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN']) && userHasAction(user, ['FEATURE_LEADS'])">
						<span class="btn-status mx-1" :class="{'on': socket != null && socket.connected && user.socket_id != null && ldq_running, 'off-danger': socket == null || socket.disconnected || user.socket_id == null || !ldq_running}" title="Lead Distribution Queue">
							<router-link to="/queue" class="span1 py-1 px-2"><i class="fa-regular fa-fw fa-random"></i></router-link>
							<router-link to="/queue" class="span2 py-1 px-2">Distribute Leads</router-link>
						</span>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['FEATURE_LEADS'])">
						<span class="btn-status mx-1" :class="{'on': socket != null && socket.connected && user.socket_id != null && ldq_running, 'off-danger': socket == null || socket.disconnected || user.socket_id == null || !ldq_running}" title="Request a Lead">
							<a href="#" @click.prevent="pullQ" class="span1 py-1 px-2">{{personalQueueCalls}}</a>
							<a href="#" @click.prevent="pullQ" class="span2 py-1 px-2">Calls in Queue</a>
						</span>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['FEATURE_LEADS'])">
						<span class="btn-status mx-1" :class="{'on': socket != null && socket.connected && user.socket_id != null && ldq_running, 'off-danger': socket == null || socket.disconnected || user.socket_id == null || !ldq_running}" title="Request a Lead">
							<a href="#" @click.prevent="pullQ" class="span1 py-1 px-2">{{personalQueueSize}}</a>
							<a href="#" @click.prevent="pullQ" class="span2 py-1 px-2">New Leads</a>
						</span>
					</li>
					<li class="nav-item"><router-link to="/bug" class="nav-link" title="Submit a Bug Report"><i class="fa-regular fa-fw fa-bug"></i></router-link></li>
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-expanded="false"><em class="dingding fa-regular fa-bell" :class="{'text-success': !newNotifications, 'text-warning': newNotifications}"></em> {{notifications.length}}</a>
						<div class="dropdown-menu dropdown-menu-lg-right position-absolute user-menu">
							<a href="#" @click.prevent="notificationsClear()" class="dropdown-item" v-if="notifications.length > 0"><em class="mr-2 fa-regular fa-trash-alt"></em> Clear All Notifications</a>
							<span class="dropdown-item" v-else><em class="mr-2 fa-regular fa-check-circle"></em> You have no notifications</span>
							<a href="#" @click.prevent="notificationGo(n.notification_id, n.link)" class="dropdown-item" :class="{'font-weight-bold': n.read != 1}" v-for="n in notifications" :key="n.notification_id"><em class="mr-2" :class="n.icon" v-if="n.icon"></em> {{n.bell_message}}</a>
						</div>
					</li>
					<li class="nav-item dropdown" v-if="userHasAction(user, ['IDDQD'])">
						<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-expanded="false"><em class="fa-regular fa-arrows-spin"></em></a>
						<div class="dropdown-menu dropdown-menu-lg-right position-absolute user-menu" :class="colours">
							<a href="#" @click.prevent="switchAccount(a.account_id)" class="dropdown-item" :class="{'font-weight-bold': a.account_id == account.account_id}" v-for="a in accounts" :key="a.account_id">{{a.account_name}}</a>
						</div>
					</li>
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-expanded="false"><i class="fa-regular fa-user-circle"></i> {{user.fname}} {{user.lname}}</a>
						<div class="dropdown-menu dropdown-menu-lg-right position-absolute user-menu" :class="colours">
							<router-link :to="'/account/' + user.account_id" class="dropdown-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])"><i class="fa-regular fa-fw fa-building"></i> My Account</router-link>
							<router-link :to="'/user/' + user.user_id" class="dropdown-item"><i class="fa-regular fa-fw fa-user"></i> My Profile</router-link>
							<div class="dropdown-item">
								<div class="btn-group btn-group-sm" role="group" aria-label="Themes">
									<a href="#" class="btn" :class="{'btn-success': user.theme == 'light', 'btn-light': user.theme != 'light'}" @click.prevent="setTheme('light')"><i class="fa-regular fa-sun"></i> Light</a>
									<a href="#" class="btn" :class="{'btn-success': user.theme == 'dark', 'btn-light': user.theme != 'dark'}" @click.prevent="setTheme('dark')"><i class="fa-regular fa-moon"></i> Dark</a>
								</div>
							</div>
							<router-link to="/logout" class="dropdown-item"><i class="fa-regular fa-fw fa-sign-out-alt"></i> Logout</router-link>
						</div>
					</li>
				</ul>
			</nav>
			<div id="call-bar" class="alert alert-success mx-4" v-show="current_call.sip_call_id != null && current_call.user_id == user.user_id">
				<div class="row m-0 p-0">
					<div class="col-4">
						<router-link :to="'/customer/' + current_call.customer_id + '/' + current_call.lead_id" class="btn btn-sm btn-primary" v-show="current_call.lead_id != null"><i class="fa-regular fa-fw fa-box-archive mr-1"></i> View Lead</router-link>
					</div>
					<div class="col-4 text-center" v-if="current_call.direction == 'in'">
						<span class="mr-5"><i class="fa-regular fa-phone-arrow-down-left mr-2"></i> {{current_call.from_number | phoneDisplay}}</span>
						<span>{{current_time - (new Date(current_call.created.substring(0, 19) + "Z").getTime() / 1000) | duration}}</span>
					</div>
					<div class="col-4 text-center" v-else-if="current_call.direction == 'out'">
						<span class="mr-5"><i class="fa-regular fa-phone-arrow-up-right mr-2"></i> {{current_call.to_number | phoneDisplay}}</span>
						<span>{{current_time - (new Date(current_call.created.substring(0, 19) + "Z").getTime() / 1000) | duration}}</span>
					</div>
					<div class="col-4 text-right">
						<a href="#" @click.prevent="transferDialog()" class="btn btn-sm btn-warning mr-1" v-if="current_call.direction == 'in'"><i class="fa-regular fa-arrow-right-arrow-left mr-1"></i> Transfer</a>
						<a href="#" @click.prevent="terminateCall(current_call.sip_call_id)" class="btn btn-sm btn-danger ml-1"><i class="fa-regular fa-fw fa-phone-slash mr-1"></i> Terminate</a>
					</div>
				</div>
			</div>
		</header>

		<div class="mb-3 d-flex container-fluid" @click="closeMenu()">
			<div v-if="logged_in">
				<ul class="nav flex-column">
					<li class="nav-item" v-if="userHasAction(user, ['FEATURE_LEADS'])">
						<router-link to="/" class="nav-link" title="My Customers"><i class="fa-regular fa-fw fa-house-user"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> My Customers</span></router-link>
					</li>
					<li class="nav-item" v-else-if="userHasAction(user, ['FEATURE_SERVICE'])">
						<router-link to="/" class="nav-link" title="My Service Requests"><i class="fa-regular fa-fw fa-house-user"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> My Service Requests</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['FEATURE_LEADS'])">
						<router-link to="/customers" class="nav-link" title="All Customers"><i class="fa-regular fa-fw fa-cabinet-filing"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> All Customers</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['FEATURE_LEADS'])">
						<router-link to="/leads" class="nav-link" title="All Leads"><i class="fa-regular fa-fw fa-file-invoice-dollar"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> All Leads</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['FEATURE_SERVICE'])">
						<router-link to="/service" class="nav-link" title="New Service Request"><i class="fa-regular fa-fw fa-clipboard-medical"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> New Service Request</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['FEATURE_SERVICE'])">
						<router-link to="/service/requests" class="nav-link" title="Search Service Requests"><i class="fa-regular fa-fw fa-clipboard-list"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Search Service Requests</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN']) && userHasAction(user, ['FEATURE_SERVICE'])">
						<router-link to="/service/forms" class="nav-link" title="Edit Service Request Forms"><i class="fa-regular fa-fw fa-clipboard-list"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Edit Service Request Forms</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['FEATURE_LEADS'])">
						<a href="#" @click.prevent="addLead()" class="nav-link"><i class="fa-regular fa-fw fa-plus"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> New Lead</span></a>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN']) && userHasAction(user, ['FEATURE_LEADS'])">
						<router-link to="/users_overview" class="nav-link" title="Users Overview"><i class="fa-regular fa-fw fa-chart-bar"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Users Overview</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'REPORTS']) && userHasAction(user, ['FEATURE_LEADS'])">
						<router-link to="/reports" class="nav-link" title="Reports Dashboard"><i class="fa-regular fa-fw fa-chart-line-up"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Reports Dashboard</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN']) && userHasAction(user, ['FEATURE_LEADS'])">
						<router-link to="/calls" class="nav-link" title="Call Dashboard"><i class="fa-regular fa-fw fa-headset"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Call Dashboard</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD'])">
						<router-link to="/accounts" class="nav-link" title="Accounts"><i class="fa-regular fa-fw fa-building"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Accounts</span></router-link>
					</li>
					<li class="nav-item" v-else-if="userHasAction(user, ['ADMIN'])">
						<router-link :to="'/account/' + user.account_id" title="My Account" class="nav-link"><i class="fa-regular fa-fw fa-building"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> My Account</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/users" class="nav-link" title="Users"><i class="fa-regular fa-fw fa-users-cog"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Users</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN']) && userHasAction(user, ['FEATURE_LEADS'])">
						<router-link to="/groups" class="nav-link" title="Groups"><i class="fa-regular fa-fw fa-user-tag"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Groups</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN']) && userHasAction(user, ['FEATURE_LEADS'])">
						<router-link to="/phone_numbers" class="nav-link" title="Phone Numbers"><i class="fa-regular fa-fw fa-phone"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Phone Numbers</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN']) && userHasAction(user, ['FEATURE_LEADS'])">
						<router-link to="/actions" class="nav-link" title="Actions"><i class="fa-regular fa-fw fa-cog"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Actions</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN']) && userHasAction(user, ['FEATURE_LEADS'])">
						<router-link to="/lead_statuses" class="nav-link" title="Lead Statuses"><i class="fa-regular fa-fw fa-flag"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Lead Statuses</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD'])">
						<router-link to="/insurers" class="nav-link" title="Insurers"><i class="fa-regular fa-fw fa-university"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Insurers</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD'])">
						<router-link to="/insurance_types" class="nav-link" title="Insurance Types"><i class="fa-regular fa-fw fa-car-crash"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Insurance Types</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN']) && userHasAction(user, ['FEATURE_LEADS'])">
						<router-link to="/lead_sources" class="nav-link" title="Lead Sources"><i class="fa-regular fa-fw fa-code"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Lead Sources</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN']) && userHasAction(user, ['FEATURE_LEADS'])">
						<router-link to="/campaigns" class="nav-link" title="Contact Strategies"><i class="fa-regular fa-fw fa-comment-dollar"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Contact Strategies</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN']) && userHasAction(user, ['FEATURE_LEADS'])">
						<router-link to="/canned_responses" class="nav-link" title="Canned Responses"><i class="fa-regular fa-fw fa-can-food"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Canned Responses</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN']) && userHasAction(user, ['FEATURE_LEADS'])">
						<router-link to="/config" class="nav-link" title="Configuration"><i class="fa-regular fa-fw fa-cogs"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Configuration</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD'])">
						<router-link to="/motd" class="nav-link" title="Message Of The Day"><i class="fa-regular fa-fw fa-bullhorn"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Message Of The Day</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/import" class="nav-link" title="Data Import"><i class="fa-regular fa-fw fa-cloud-upload"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Data Import</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<a :href="api_server_no_cf + '/leads/export'" target="_blank" class="nav-link" title="Data Export"><i class="fa-regular fa-fw fa-cloud-download"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Data Export</span></a>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'DATA_ADMIN'])">
						<router-link to="/account/purge" class="nav-link" title="Purge Data"><i class="fa-regular fa-fw fa-biohazard"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Purge Data</span></router-link>
					</li>
					<li class="nav-item">
						<router-link :to="'/user/' + user.user_id" title="My Profile" class="nav-link"><i class="fa-regular fa-fw fa-user"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> My Profile</span></router-link>
					</li>
					<li class="nav-item">
						<router-link to="/help" class="nav-link" title="Help"><i class="fa-regular fa-fw fa-question-circle"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Help</span></router-link>
					</li>
					<li class="nav-item">
						<router-link to="/bug" class="nav-link" title="Submit a Bug Report"><i class="fa-regular fa-fw fa-bug"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Submit a Bug Report</span></router-link>
					</li>
					<li class="nav-item" v-if="logged_in">
						<router-link to="/logout" class="nav-link" title="Logout"><i class="fa-regular fa-fw fa-sign-out-alt"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Logout</span></router-link>
					</li>
					<!-- <li class="nav-item mt-5" v-if="logged_in">
						<a href="" @click.prevent="test" class="nav-link"><i class="fa-regular fa-fw fa-magic"></i><span v-show="menu_open || user.pin_sidebar" class="mx-2"> Testing...1...2..3...</span></a>
					</li> -->
					<li class="nav-item text-center pt-3">v{{version}}</li>
				</ul>
			</div>

			<div :class="className" class="pt-3" @click="closeMenu()">
				<div v-for="(n, index) in alerts" :key="index">
					<div v-if="n.type == 'message'" class="alert alert-info text-center notification">
						<p>{{n.bell_message}}</p>
						<div>
							<button type="button" class="mx-1" :class="b.class" @click="b.action(index)" v-for="(b, index2) in n.buttons" :key="index2">{{b.text}}</button>
						</div>
					</div>
				</div>

				<div class="alert alert-danger text-center" v-if="logged_in && (socket == null || !socket.connected || user.socket_id == null)">
					<i class="fa-regular fa-exclamation-circle"></i> Something is wrong with your connection to the server and you may not be able to receive leads. <a href="#" @click.prevent="configureSocket()">Click here to try fixing the issue</a> or reload the page.
				</div>

				<router-view @login="login" @logout="logout" :app="this" />
			</div>
		</div>

		<footer class="container-fluid p-0 mb-3" @click="closeMenu()" v-if="logged_in">
			<hr>
			<nav>
				<ul class="nav d-flex justify-content-center">
					<li class="nav-item">
						<router-link to="/help" class="nav-link"><i class="fa-regular fa-question-circle"></i> Help</router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link :to="'/account/' + user.account_id" class="nav-link"><i class="fa-regular fa-building"></i> My Account</router-link>
					</li>
					<li class="nav-item">
						<router-link :to="'/user/' + user.user_id" class="nav-link"><i class="fa-regular fa-user"></i> My Profile</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/logout" class="nav-link"><i class="fa-regular fa-sign-out-alt"></i> Logout</router-link>
					</li>
				</ul>
			</nav>
		</footer>

		<div class="modal fade" tabindex="-1" id="transfer-modal" data-backdrop="static" data-keyboard="false" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Transfer Call</h5>
					</div>
					<div class="modal-body">
						<div class="row mb-2">
							<div class="col-4"><label for="x">Select a User</label></div>
							<div class="col-8"><UserSelector :model="tx" field="user_id" :allowEmpty="true" :callback="setUserNumber" /></div>
						</div>
						<div class="row">
							<div class="col-4"><label for="x">Phone Number</label></div>
							<div class="col-8"><input type="tel" class="form-control" v-model="tx.phone" required /></div>
						</div>
					</div>
					<div class="modal-footer">
						<div class="text-center">
							<button type="button" class="btn btn-sm btn-primary mr-1" @click="transferCall(current_call.sip_call_id)"><i class="fa-regular fa-arrow-right-arrow-left"></i> Transfer Call</button>
							<button type="button" class="btn btn-sm btn-dark ml-1" @click="transferClose()"><i class="fa-regular fa-times"></i> Cancel</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<LeadTriage :lead="triage_lead" />

		<div class="toast incoming-toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-autohide="false" id="call-toast">
			<div class="toast-header">
				<strong class="mr-auto">Incoming Call</strong>
			</div>
			<div class="toast-body">
				<p v-if="incoming_call.assigned_user_id == user.user_id">From {{incoming_call.cid | phoneDisplay}}<span v-show="incoming_call.from"> ({{incoming_call.from}})</span></p>
				<p v-if="incoming_call.assigned_user_id == user.user_id" class="font-weight-bold">You are the assigned user for this customer.</p>
			</div>
			<div class="toast-footer py-2 text-center text-nowrap">
				<button type="button" class="mx-1 ml-2 btn btn-sm btn-success" @click="answerCall(incoming_call.lead_id, incoming_call.sip_call_id)">Answer Call</button>
				<div class="d-inline" v-if="incoming_call.assigned_user_id == user.user_id">
					<button type="button" class="mx-1 btn btn-sm btn-info" @click="rejectCallVoicemail(incoming_call.lead_id, incoming_call.sip_call_id)">Voicemail</button>
					<div class="dropdown d-inline mx-1">
						<button type="button" class="btn btn-sm btn-warning dropdown-toggle" data-toggle="dropdown" aria-expanded="false">SMS Reply</button>
						<div class="dropdown-menu">
							<a class="dropdown-item" href="#" @click.prevent="rejectCallWithSMS(incoming_call.lead_id, incoming_call.sip_call_id, r.canned_response)" v-for="r in quick_replies" :key="r.canned_response_id" :value="r">{{r.canned_response.substring(0, 100)}}</a>
						</div>
					</div>
				</div>
				<button type="button" class="ml-4 mr-2 btn btn-sm btn-danger" @click="rejectCall(incoming_call.lead_id, incoming_call.sip_call_id)">Reject Call</button>
			</div>
		</div>

		<div class="toast incoming-toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-autohide="false" id="lead-toast">
			<div class="toast-header">
				<strong class="mr-auto">Incoming Lead</strong>
			</div>
			<div class="toast-body">
				<p v-if="incoming_lead.assigned_user_id == user.user_id" class="font-weight-bold">You are the assigned user for this customer.</p>
			</div>
			<div class="toast-footer py-2 text-center">
				<button type="button" class="mx-1" :class="b.class" @click="b.action(index)" v-for="(b, index) in incoming_lead.buttons" :key="index">{{b.text}}</button>
			</div>
		</div>

		<div class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-delay="60000" id="notification-toast">
			<div class="toast-header">
				<strong class="mr-auto">New Alert</strong>
				<button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close"><span aria-hidden="true">&times;</span></button>
			</div>
			<div class="toast-body">
				<a v-if="lastNotification != null" href="#" @click.prevent="notificationGo(lastNotification.notification_id, lastNotification.link)"><em class="mr-2" :class="lastNotification.icon" v-if="lastNotification.icon"></em> {{lastNotification.bell_message}}</a>
			</div>
		</div>
	</div>
</template>

<style>
	#logo {
		width: 175px;
		height: auto;
		max-height: 60px;
	}
	.motd-x
	{
		position: absolute;
		top: 12px;
		right: 5px;
	}
</style>

<script>
import { mapGetters } from 'vuex';
import jQuery from 'jquery';

export default {
	name: 'App',
	components:
	{
		'LeadTriage': () => import('./components/LeadTriage.vue'),
		'UserSelector': () => import('./components/UserSelector.vue')
	},
	data() {
		return {
			version: "3.2.13",
			server_version: "3.2.13",
			first_load: true,
			menu_open: false,
			alerts: [],
			ldq_running: false,
			drip_running: false,
			close_button: {"class": "btn btn-sm btn-light", "text": "Close", "action": (index) => {this.cancelAlert(this.alerts[index].id)}},
			sound_files: {
				"notification": "/sounds/mixkit-modern-technology-select-3124.wav",
				"incoming_call": "/sounds/mixkit-quick-win-video-game-notification-269.wav",
				"incoming_lead": "/sounds/mixkit-select-click-1109.wav"
			},
			sounds: {
				"notification": null,
				"incoming_call": null,
				"incoming_lead": null
			},
			idle_timer: 0,
			queue: null,
			incoming_call: {
				sip_call_id: null,
				from_number: null,
				assigned_user_id: null,
				created: new Date().toISOString(),
				buttons: []
			},
			incoming_lead: {
				lead_id: null,
				assigned_user_id: null,
				created: new Date().toISOString(),
				buttons: []
			},
			triage_lead: {
				lead_id: null,
				address1: "",
				city: "",
				email: "",
				fname: "",
				insurance_type_name: "",
				lead_source_name: "",
				lname: "",
				phone: "",
				postalcode: "",
			},
			current_call: {
				sip_call_id: null,
				direction: null,
				customer_id: null,
				lead_id: null,
				created: "",
				to_number: null,
				from_number: null
			},
			tx: {
				user_id: null,
				phone: ""
			},
			idle_throttle: false,
			hide_motd: false
		}
	},
	computed: {
		personalQueueSize()
		{
			if(this.queue == null)
			{
				return 0;
			}

			let count = 0;
			const items = this.queue.queue;

			for(let lead_source_id in items)
			{
				// "null" as a string indicates no lead source.
				if(lead_source_id == "null" || this.user.lead_source_ids.indexOf(Number(lead_source_id)) !== -1)
				{
					count += items[lead_source_id];
				}
			}

			return count;
		},
		personalQueueCalls()
		{
			if(this.queue == null)
			{
				return 0;
			}

			let count = 0;
			const items = this.queue.calls;

			for(let lead_source_id in items)
			{
				// "null" as a string indicates no lead source.
				if(lead_source_id == "null" || this.user.lead_source_ids.indexOf(Number(lead_source_id)) !== -1)
				{
					count += items[lead_source_id];
				}
			}

			return count;
		},
		lastNotification()
		{
			if(this.notifications.length == 0)
			{
				return null;
			}

			return this.notifications[0];
		},
		className()
		{
			if(["Login", "Logout"].indexOf(this.$route.name) != -1)
			{
				return "container";
			}
			else
			{
				return "container-fluid";
			}

			// if(["Dashboard", "Leads", "UsersOverview", "Customer", "Customer2"].indexOf(this.$route.name) != -1)
			// {
			// 	return "container-fluid";
			// }
			// else
			// {
			// 	return "container-fluid";
			// 	// return "container";
			// }
		},
		newNotifications()
		{
			for(let n in this.notifications)
			{
				if(n.read != 1)
				{
					return true;
				}
			}

			return false;
		},
		...mapGetters([
			"user",
			"account",
			"accounts",
			"logged_in",
			"lead_statuses",
			"insurance_types",
			"insurers",
			"groups",
			"phone_numbers",
			"roles",
			"lead_timer",
			"loading_count",
			"socket",
			"current_time",
			"notifications",
			"users",
			"quick_replies",
			"motd"
		])
	},
	methods: {
		checkSession()
		{
			this.CORS("GET", "/authenticate", "", (response) =>
			{
				this.$store.commit("user", response.user);
				this.$store.commit("logged_in", true);

				// If the user logged in on the generic domain, forward them to their subdomain.
				if(window.location.hostname == "goliathlms.com" && this.user.account_id == 1)
				{
					window.location = "https://mitch.goliathlms.com";
					return;
				}
				else if(window.location.hostname == "goliathlms.com" && this.user.account_id == 2)
				{
					window.location = "https://western.goliathlms.com";
					return;
				}
				else if(window.location.hostname == "goliathlms.com" && this.user.account_id == 3)
				{
					window.location = "https://jones.goliathlms.com";
					return;
				}
				else if(window.location.hostname == "goliathlms.com" && this.user.account_id == 6)
				{
					window.location = "https://guild.goliathlms.com";
					return;
				}
				else if(window.location.hostname == "goliathlms.com" && this.user.account_id == 7)
				{
					window.location = "https://macleod.goliathlms.com";
					return;
				}

				if(this.first_load)
				{
					// Load all globally used supporting data.
					this.loadConfig();
					this.loadNotifications();
					this.loadLeadStatuses();
					this.loadLeadSources();
					this.loadInsuranceTypes();
					this.loadInsurers();
					this.loadGroups();
					this.loadNumbers();
					this.loadRoles();
					this.loadActions();
					this.loadGroups();
					this.loadAccounts();
					this.loadUsers();
					this.loadCannedResponses();
					this.loadFeatures();
					this.loadFilters();
				}

				// Connect the socket, if necessary.
				if(this.first_load || this.socket == null || this.socket.disconnected || this.user.socket_id == null)
				{
					this.configureSocket();
				}

				this.first_load = false;
			});
		},
		test()
		{
			this.CORS("GET", "/test", "", () =>
			{
				// pass
			});
		},
		configureSocket()
		{
			// Remove any event listeners BEFORE creating a new socket, otherwise the references are not removed.
			// this.$store.commit("stopListening", "user_flags", this.userFlags);
			// this.$store.commit("stopListening", "lead_assigned", this.leadAssigned);
			// this.$store.commit("stopListening", "incoming_call", this.incomingCall);
			// this.$store.commit("stopListening", "cancel_call", this.cancelCall);

			// Disconnect the socket, if necessary.
			this.$store.commit("disconnect");
			this.$store.commit("initialize");

			// Add event handlers for socket events.
			this.$store.commit("startListening", {"event": "connect", "callback": () =>
				{
					// Connect the user ID with its corresponding socket ID.
					this.user.socket_id = this.socket.id;
					this.socket.emit("user", this.user);
				}
			});

			this.$store.commit("startListening", {"event": "disconnect", "callback": (reason) =>
				{
					if(reason === "io server disconnect")
					{
						this.configureSocket();
					}
				}
			});

			this.$store.commit("startListening", {"event": "user_flags", "callback": this.userFlags});
			// this.$store.commit("startListening", {"event": "lead_assigned", "callback": this.leadAssigned});
			this.$store.commit("startListening", {"event": "incoming_call", "callback": this.incomingCall});
			this.$store.commit("startListening", {"event": "cancel_call", "callback": this.cancelCall});
			this.$store.commit("startListening", {"event": "incoming_lead", "callback": this.incomingLead});
			this.$store.commit("startListening", {"event": "cancel_lead", "callback": this.cancelLead});
			this.$store.commit("startListening", {"event": "notification", "callback": this.notification});

			// Listen for reload events.
			this.$store.commit("startListening", {"event": "reload_lead_statuses", "callback": this.loadLeadStatuses});
			this.$store.commit("startListening", {"event": "reload_lead_sources", "callback": this.loadLeadSources});
			this.$store.commit("startListening", {"event": "reload_insurance_types", "callback": this.loadInsuranceTypes});
			this.$store.commit("startListening", {"event": "reload_insurers", "callback": this.loadInsurers});
			this.$store.commit("startListening", {"event": "reload_groups", "callback": this.loadGroups});
			this.$store.commit("startListening", {"event": "reload_numbers", "callback": this.loadNumbers});
			// this.$store.commit("startListening", {"event": "reload_roles", "callback": this.loadRoles});
			this.$store.commit("startListening", {"event": "reload_actions", "callback": this.loadActions});
			this.$store.commit("startListening", {"event": "reload_groups", "callback": this.loadGroups});
			this.$store.commit("startListening", {"event": "reload_accounts", "callback": this.loadAccounts});
			this.$store.commit("startListening", {"event": "reload_users", "callback": this.loadUsers});
			this.$store.commit("startListening", {"event": "reload_canned_responses", "callback": this.loadCannedResponses});
			this.$store.commit("startListening", {"event": "motd", "callback": this.newMOTD});
			this.$store.commit("startListening", {"event": "queue_size", "callback": this.queueSize});
			this.$store.commit("startListening", {"event": "queue_status", "callback": this.queueStatus});
			this.$store.commit("startListening", {"event": "drip_status", "callback": this.dripStatus});
			this.$store.commit("startListening", {"event": "call_status", "callback": this.callStatus});
			this.$store.commit("startListening", {"event": "version", "callback": this.versionCheck});
			this.$store.commit("connect");
		},
		login()
		{
			this.$store.commit("logged_in", true);

			// Connect the socket.
			this.configureSocket();
		},
		logout()
		{
			this.$store.commit("user", {user_id: null, actions: [], available: 0, receiving_leads: 0, socket_id: null});
			this.$store.commit("logged_in", false);

			// Disconnect the socket, if necessary.
			this.$store.commit("disconnect");
		},
		toggleMenu()
		{
			if(!this.logged_in)
			{
				return;
			}

			this.menu_open = (this.menu_open == 1 ? 0 : 1);
		},
		closeMenu()
		{
			this.menu_open = 0;
		},
		loadNotifications(callback)
		{
			this.$store.commit("startLoading");

			this.CORS("GET", "/notifications", null,
				(data) =>
				{
					this.$store.commit("notifications", data);
					this.$store.commit("stopLoading");

					if(typeof callback == "function")
					{
						callback();
					}
				},
				(response) =>
				{
					this.showError("Error loading notifications", response.responseText, true, null);
					this.$store.commit("stopLoading");
				})
		},
		loadConfig(callback)
		{
			this.$store.commit("startLoading");

			this.CORS("GET", "/config", null,
				(data) =>
				{
					this.$store.commit("config", data);
					this.$store.commit("stopLoading");

					if(typeof callback == "function")
					{
						callback();
					}
				},
				(response) =>
				{
					this.showError("Error loading config", response.responseText, true, null);
					this.$store.commit("stopLoading");
				})
		},
		loadLeadStatuses()
		{
			this.$store.commit("startLoading");

			this.CORS("GET", "/lead_statuses", null,
				(data) =>
				{
					this.$store.commit("lead_statuses", data);
					this.$store.commit("stopLoading");
				},
				(response) =>
				{
					this.showError("Error loading lead statuses", response.responseText, true, null);
					this.$store.commit("stopLoading");
				})
		},
		loadLeadSources()
		{
			this.$store.commit("startLoading");

			this.CORS("GET", "/lead_sources", null,
				(data) =>
				{
					this.$store.commit("lead_sources", data);
					this.$store.commit("stopLoading");
				},
				(response) =>
				{
					this.showError("Error loading lead sources", response.responseText, true, null);
					this.$store.commit("stopLoading");
				})
		},
		loadInsuranceTypes()
		{
			this.$store.commit("startLoading");

			this.CORS('GET', "/insurance_types", null,
				(data) =>
				{
					this.$store.commit("insurance_types", data);
					this.$store.commit("stopLoading");
				},
				(response) =>
				{
					this.$store.commit("stopLoading");
					this.showError("Error", "Error loading insurance types: " + response.responseText, true, null);
				});
		},
		loadInsurers()
		{
			this.$store.commit("startLoading");

			this.CORS('GET', "/insurers", null,
				(data) =>
				{
					this.$store.commit("insurers", data);
					this.$store.commit("stopLoading");
				},
				(response) =>
				{
					this.$store.commit("stopLoading");
					this.showError("Error", "Error loading insurers: " + response.responseText, true, null);
				});
		},
		loadGroups()
		{
			this.$store.commit("startLoading");

			this.CORS('GET', "/groups", null,
				(data) =>
				{
					this.$store.commit("groups", data);
					this.$store.commit("stopLoading");
				},
				() =>
				{
					this.$store.commit("stopLoading");
					this.showError("Error", "Error loading user groups.", true, null);
				});
		},
		loadNumbers()
		{
			this.$store.commit("startLoading");

			this.CORS('GET', "/phone_numbers", null,
				(data) =>
				{
					this.$store.commit("phone_numbers", data);
					this.$store.commit("stopLoading");
				},
				() =>
				{
					this.$store.commit("stopLoading");
					this.showError("Error", "Error loading phone numbers.", true, null);
				});
		},
		loadRoles()
		{
			this.$store.commit("startLoading");

			this.CORS('GET', "/roles", null,
				(data) =>
				{
					this.$store.commit("roles", data);
					this.$store.commit("stopLoading");
				},
				(response) =>
				{
					this.$store.commit("stopLoading");
					this.showError("Error", "Error loading user roles: " + response.responseText, true, null);
				});
		},
		loadActions()
		{
			this.$store.commit("startLoading");

			this.CORS('GET', "/actions", null,
				(data) =>
				{
					this.$store.commit("actions", data);
					this.$store.commit("stopLoading");
				},
				(response) =>
				{
					this.$store.commit("stopLoading");
					this.showError("Error", "Error loading lead actions: " + response.responseText, true, null);
				});
		},
		loadFeatures()
		{
			this.$store.commit("startLoading");

			this.CORS('GET', "/features", null,
				(data) =>
				{
					this.$store.commit("features", data);
					this.$store.commit("stopLoading");
				},
				(response) =>
				{
					this.$store.commit("stopLoading");
					this.showError("Error", "Error loading account features: " + response.responseText, true, null);
				});
		},
		loadFilters()
		{
			this.$store.commit("startLoading");

			this.CORS('GET', "/filters", null,
				(data) =>
				{
					this.$store.commit("filter_fields", data);
					this.$store.commit("stopLoading");
				},
				(response) =>
				{
					this.$store.commit("stopLoading");
					this.showError("Error", "Error loading available filters: " + response.responseText, true, null);
				});
		},
		loadCannedResponses()
		{
			this.$store.commit("startLoading");

			this.CORS('GET', "/canned_responses", null,
				(data) =>
				{
					this.$store.commit("canned_responses", data);
					this.$store.commit("stopLoading");
				},
				(response) =>
				{
					this.$store.commit("stopLoading");
					this.showError("Error", "Error loading lead canned responses: " + response.responseText, true, null);
				});
		},
		loadAccounts()
		{
			this.$store.commit("startLoading");

			this.CORS('GET', "/accounts", null,
				(data) =>
				{
					this.$store.commit("accounts", data);
					this.$store.commit("stopLoading");
				},
				(response) =>
				{
					this.$store.commit("stopLoading");
					this.showError("Error", "Error loading accounts: " + response.responseText, true, null);
				});
		},
		loadUsers()
		{
			this.$store.commit("startLoading");

			this.CORS('GET', "/users", null,
				(data) =>
				{
					this.$store.commit("users", data);
					this.$store.commit("stopLoading");
				},
				(response) =>
				{
					this.$store.commit("stopLoading");
					this.showError("Error", "Error loading users: " + response.responseText, true, null);
				});
		},
		answerCall(lead_id, sip_call_id)
		{
			// This function does NOT cancel call alerts.
			// This will be handled as a server event notifying ALL users that the call was answered.
			// It serves as validation that the call was actually answered.

			this.$store.commit("startLoading");
			this.cancelCall(sip_call_id);

			this.CORS('PUT', "/calls/" + sip_call_id + "/answer/" + lead_id, null,
				(data) =>
				{
					this.$store.commit("stopLoading");

					if(data.result == "match")
					{
						this.$router.push("/customer/" + data.customer_id + "/" + data.lead_id).catch(() => { /* ignore error */ });
					}
					else
					{
						this.triage_lead = data;
						jQuery("#call-toast").toast("hide");
						jQuery("#lead-toast").toast("hide");
						jQuery("#transfer-modal").modal("hide");
						jQuery("#triage-modal").modal({show: true, focus: true, keyboard: false});
					}
				},
				(response) =>
				{
					this.$store.commit("stopLoading");
					this.cancelCall(sip_call_id);

					if(response.status == 409)		// Somebody else answered it.
					{
						this.showError("Error", "Error answering call: " + response.responseText, true, null);
					}
					else if(response.status == 400)  // Recoverable error.
					{
						this.showError("Call Ended", "The call was disconnected before it could be transferred. Click ok to call them back ASAP!", true, () =>
							{
								let data = response.responseJSON;

								if(data.result == "match")
								{
									this.$router.push("/customer/" + data.customer_id + "/" + data.lead_id).catch(() => { /* ignore error */ });
								}
								else
								{
									this.triage_lead = data;
									jQuery("#call-toast").toast("hide");
									jQuery("#lead-toast").toast("hide");
									jQuery("#transfer-modal").modal("hide");
									jQuery("#triage-modal").modal({show: true, focus: true, keyboard: false});
								}
							});
					}
					else
					{
						this.showError("Error", "Error answering call: " + response.responseText, true, null);
					}
				});
		},
		rejectCall(lead_id, sip_call_id)
		{
			this.$store.commit("startLoading");

			this.CORS('PUT', "/calls/" + sip_call_id + "/reject/" + lead_id, null,
				() =>
				{
					this.$store.commit("stopLoading");

					// Only cancel this notification if it was successfully rejected.
					// If an error occurs, its still this agent's duty to deal with the call.
					this.cancelCall(sip_call_id);
				},
				(response) =>
				{
					this.$store.commit("stopLoading");
					this.showError("Error", "Error rejecting call: " + response.responseText, true, null);
				});
		},
		rejectCallWithSMS(lead_id, sip_call_id, message)
		{
			this.$store.commit("startLoading");

			this.CORS('PUT', "/calls/" + sip_call_id + "/reject_sms/" + lead_id, JSON.stringify({"message": message}),
				() =>
				{
					this.$store.commit("stopLoading");

					// Only cancel this notification if it was successfully rejected.
					// If an error occurs, its still this agent's duty to deal with the call.
					this.cancelCall(sip_call_id);
				},
				(response) =>
				{
					this.$store.commit("stopLoading");
					this.showError("Error", "Error replying to call: " + response.responseText, true, null);
				});
		},
		rejectCallVoicemail(lead_id, sip_call_id)
		{
			this.$store.commit("startLoading");

			this.CORS('PUT', "/calls/" + sip_call_id + "/reject_voicemail/" + lead_id, null,
				() =>
				{
					this.$store.commit("stopLoading");

					// Only cancel this notification if it was successfully rejected.
					// If an error occurs, its still this agent's duty to deal with the call.
					this.cancelCall(sip_call_id);
				},
				(response) =>
				{
					this.$store.commit("stopLoading");
					this.showError("Error", "Error replying to call: " + response.responseText, true, null);
				});
		},
		acceptLead(lead_id)
		{
			// This function does NOT cancel lead alerts.
			// This will be handled as a server event notifying ALL users that the lead was accepted.
			// It serves as validation that the lead was actually accepted.

			this.$store.commit("startLoading");

			this.CORS('PUT', "/leads/" + lead_id + "/accept", null,
				(data) =>
				{
					this.$store.commit("stopLoading");

					if(data.result == "match")
					{
						this.$router.push("/customer/" + data.customer_id + "/" + data.lead_id).catch(() => { /* ignore error */ });
					}
					else
					{
						this.triage_lead = data;
						jQuery("#call-toast").toast("hide");
						jQuery("#lead-toast").toast("hide");
						jQuery("#transfer-modal").modal("hide");
						jQuery("#triage-modal").modal({show: true, focus: true, keyboard: false});
					}
				},
				(response) =>
				{
					// 409 = somebody else accepted it.
					if(response.status == 409)
					{
						this.cancelLead({"lead_id": lead_id});
					}

					this.$store.commit("stopLoading");
					this.showError("Error", "Error accepting lead: " + response.responseText, true, null);
				});
		},
		rejectLead(lead_id)
		{
			this.$store.commit("startLoading");

			this.CORS('PUT', "/leads/" + lead_id + "/reject", null,
				() =>
				{
					this.$store.commit("stopLoading");

					// Only cancel this notification if it was successfully rejected.
					// If an error occurs, its still this agent's duty to deal with it.
					this.cancelLead({"lead_id": lead_id});
				},
				(response) =>
				{
					this.$store.commit("stopLoading");
					this.showError("Error", "Error rejecting lead: " + response.responseText, true, null);
				});
		},
		userFlags(u)
		{
			// We only care if this is us.
			if(u.user_id != this.user.user_id)
			{
				return;
			}

			// Update the user flags.
			let updated_user = this.user;
			updated_user[u.field] = u.value
			this.$store.commit("user", updated_user);

			// If this is the idle flag being removed, reset the idle timer.
			// This will keep the idle status "in sync" between multiple tabs or browsers.
			// It prevents them from continually resetting each other.
			if(u.field == "idle" && u.value == 0)
			{
				this.idle_timer = 0;
			}
		},
		// leadAssigned(lead)
		// {
		// 	let id = "lead_assigned_" + lead.lead_id;

		// 	// Cancel this call notification, in case its already posted.
		// 	this.cancelAlert(id);

		// 	// Display notification.
		// 	this.alerts.push({"id": id, "type": "message", "message": "You've been assigned to a lead.", "bell_message": "You've been assigned to a lead.", "buttons": [
		// 		{"class": "btn btn-sm btn-primary", "text": "View Lead", "action": (index) => {
		// 			this.$router.push("/customer/" + lead.customer_id + "/" + lead.lead_id).catch(() => { /* ignore error */ });
		// 			this.alerts.splice(index, 1);
		// 		}},
		// 		this.close_button
		// 	]});
		// },
		incomingCall(call)
		{
			// Broadcasted calls go to everybody.
			// Ignore them if the user is already on the phone or not receiving calls.
			// UNLESS they're the assigned agent.
			if((!this.user.available || !this.user.receiving_leads) && this.user.user_id != call.assigned_user_id)
			{
				return;
			}

			// let id = "call" + call.sip_call_id;

			// Play alert, unless the user is on the phone.
			if(this.user.available && this.user.notification_volume > 0 && this.sounds.incoming_call == null)
			{
				this.sounds.incoming_call = new Audio(this.sound_files.incoming_call);
				this.sounds.incoming_call.loop = false;
				this.sounds.incoming_call.volume = this.user.notification_volume / 100;
				this.sounds.incoming_call.onended = () => {
					this.sounds.incoming_call = null;
				}
				try {
					this.sounds.incoming_call.play();
				} catch(e) {
					// NotAllowedError: play() failed because the user didn't interact with the document first.
					this.sounds.incoming_call = null;
				}
			}

			// Display an incoming call notification.
			// this.alerts.push({"id": id, "type": "call", "message": "", "from_number": call.cid, "created": call.created, "lead_id": call.lead_id, "assigned_user_id": call.assigned_user_id, "buttons": [
			// 	{"class": "btn btn-sm btn-primary", "text": "Answer Call", "action": () => {this.answerCall(call.lead_id, call.sip_call_id)}},
			// 	{"class": "btn btn-sm btn-danger", "text": "Reject Call", "action": () => {this.rejectCall(call.lead_id, call.sip_call_id)}}
			// ]});
			//
			this.incoming_call = call;
			jQuery("#lead-toast").toast("hide");
			jQuery("#transfer-modal").modal("hide");
			jQuery("#call-toast").toast("show");
		},
		cancelCall(sip_call_id)
		{
			// Stop ringing.
			// try
			// {
			// 	this.sounds.incoming_call.pause();
			// 	this.sounds.incoming_call = null;
			// }
			// catch(e)
			// {
			// 	// pass
			// }

			// Don't close things down unless its our current call being cancelled.
			if(sip_call_id != this.incoming_call.sip_call_id)
			{
				return;
			}

			jQuery("#call-toast").toast("hide");
			this.incoming_call = {
				sip_call_id: sip_call_id,
				from_number: null,
				assigned_user_id: null,
				created: new Date().toISOString(),
				buttons: []
			};
		},
		incomingLead(lead)
		{
			// If the lead triage dialog is open, ignore the lead.
			if(jQuery("#triage-modal:visible").length == 1)
			{
				return;
			}

			// Play alert, unless the user is on the phone.
			if(this.user.available && this.user.notification_volume > 0 && this.sounds.incoming_lead == null)
			{
				this.sounds.incoming_lead = new Audio(this.sound_files.incoming_lead);
				this.sounds.incoming_lead.loop = false;
				this.sounds.incoming_lead.volume = this.user.notification_volume / 100;
				this.sounds.incoming_lead.onended = () => {
					this.sounds.incoming_lead = null;
				}
				try {
					this.sounds.incoming_lead.play();
				} catch(e) {
					// NotAllowedError: play() failed because the user didn't interact with the document first.
					this.sounds.incoming_lead = null;
				}
			}

			// Display an incoming lead notification.
			// this.alerts.push({"id": id, "type": "lead", "message": "", "created": lead.created, "lead_id": lead.lead_id, "assigned_user_id": lead.assigned_user_id, "buttons": [
			// 	{"class": "btn btn-sm btn-primary", "text": "Accept Lead", "action": () => {this.acceptLead(lead.lead_id)}},
			// 	{"class": "btn btn-sm btn-danger", "text": "Reject Lead", "action": () => {this.rejectLead(lead.lead_id)}}
			// ]});
			lead.buttons = [
				{"class": "btn btn-sm btn-success", "text": "Accept Lead", "action": () => {this.acceptLead(lead.lead_id)}},
				{"class": "btn btn-sm btn-danger", "text": "Reject Lead", "action": () => {this.rejectLead(lead.lead_id)}}
			]
			this.incoming_lead = lead;
			jQuery("#call-toast").toast("hide");
			jQuery("#transfer-modal").modal("hide");
			jQuery("#lead-toast").toast("show");
		},
		cancelLead(data)
		{
			// Don't close things down unless its our current call being cancelled.
			if(data.lead_id != this.incoming_lead.lead_id)
			{
				return;
			}

			jQuery("#lead-toast").toast("hide");
			this.incoming_lead = {
				lead_id: data.lead_id,
				assigned_user_id: null,
				created: new Date().toISOString(),
				buttons: []
			};
		},
		cancelAlert(id)
		{
			// Locate the call that has been cancelled and remove it from alerts.
			for(let i=0; i<this.alerts.length; i++)
			{
				if(this.alerts[i].id == id)
				{
					this.alerts.splice(i, 1);
					break;
				}
			}
		},
		addLead()
		{
			this.triage_lead = {
				lead_id: null,
				lead_source_id: null,
				insurance_type_id: null,
				user_id: null,
				fname: null,
				lname: null,
				address1: null,
				city: null,
				postalcode: null,
				phone: null,
				email: null,
				disable_drip: 1,
				queued: 0
			};
			jQuery("#call-toast").toast("hide");
			jQuery("#lead-toast").toast("hide");
			jQuery("#transfer-modal").modal("hide");
			jQuery("#triage-modal").modal({show: true, focus: true, keyboard: false});
		},
		idle()
		{
			// If the user is already idle, don't spam the server.
			if(this.user.idle == 1)
			{
				return;
			}

			// If the idle throttle is enabled, take a break.
			if(this.idle_throttle)
			{
				return;
			}

			// If the toggle fails to respond, avoid spamming attempts at a server that is probably down.
			this.idle_throttle = true;
			setTimeout(() => {
				this.idle_throttle = false;
			}, 10000);

			this.$store.commit("startLoading");

			this.CORS("POST", "/user_field_toggle", JSON.stringify({user_id: this.user.user_id, field: "idle", value: 1}),
			() =>
			{
				this.$store.commit("stopLoading");
				this.user.idle = 1;
			},
			() =>
			{
				this.$store.commit("stopLoading");
			})
		},
		notIdle()
		{
			// If the user is not idle, don't spam the server.
			if(this.user.idle != 1)
			{
				return;
			}

			// If the idle throttle is enabled, take a break.
			if(this.idle_throttle)
			{
				return;
			}

			// If the toggle fails to respond, avoid spamming attempts at a server that is probably down.
			this.idle_throttle = true;
			setTimeout(() => {
				this.idle_throttle = false;
			}, 10000);

			this.$store.commit("startLoading");

			this.CORS("POST", "/user_field_toggle", JSON.stringify({user_id: this.user.user_id, field: "idle", value: 0}),
			() =>
			{
				this.$store.commit("stopLoading");
				this.user.idle = 0;
			},
			() =>
			{
				this.$store.commit("stopLoading");
			})
		},
		notification()
		{
			// In order to properly roll up notifications we'll just reload them from the server.
			this.loadNotifications(() => {
				if(this.lastNotification == null) {
					return;
				}
				jQuery("#notification-toast").toast("show");
				jQuery(".dingding").addClass("fa-spin");

				// Play alert, unless the user is on the phone.
				if(this.user.available && this.user.notification_volume > 0 && this.sounds.notification == null)
				{
					this.sounds.notification = new Audio(this.sound_files.notification);
					this.sounds.notification.loop = false;
					this.sounds.notification.volume = this.user.notification_volume / 100;
					this.sounds.notification.onended = () => {
						this.sounds.notification = null;
					}
					try {
						this.sounds.notification.play();
					} catch(e) {
						// NotAllowedError: play() failed because the user didn't interact with the document first.
						this.sounds.notification = null;
					}
				}

				setTimeout(() => {
					jQuery(".dingding").removeClass("fa-spin");
				}, 5000);
			});
		},
		queueSize(q)
		{
			this.queue = q;
		},
		queueStatus(status)
		{
			this.ldq_running = status;
		},
		dripStatus(status)
		{
			this.drip_running = status;
		},
		newMOTD(motd)
		{
			this.hide_motd = false;
			this.$store.commit("motd", motd);
		},
		toggleField(field)
		{
			this.$store.commit("startLoading");
			// Toggle the value.
			this.user[field] = !this.user[field];

			this.CORS("POST", "/user_field_toggle", JSON.stringify({user_id: this.user.user_id, field: field, value: this.user[field]}),
			(response) =>
			{
				this.user[field] = response[field];	// Not necessary, but it makes sure the save worked.
				this.$store.commit("stopLoading");

				// If the user has just become available, pull a lead.
				if(this.user.receiving_leads && this.user.available && !this.user.idle)
				{
					this.pullQ();
				}
			},
			(response) =>
			{
				this.showError("Error Saving User", response.responseText, true, null);
				this.$store.commit("stopLoading");
				// Restore the old value.
				this.user[field] = !this.user[field];
			})
		},
		setTheme(theme)
		{
			this.$store.commit("startLoading");
			// Toggle the value.
			const old_theme = this.user["theme"];
			this.user["theme"] = theme;

			this.CORS("POST", "/user_field_toggle", JSON.stringify({user_id: this.user.user_id, field: "theme", value: theme}),
			() =>
			{
				try{document.getElementById("theme-css").href = this.api_server + "/assets/css/" + this.user["active_account_id"] + "/" + this.user["theme"];}catch(e){/**/}
				this.$store.commit("stopLoading");
			},
			(response) =>
			{
				this.showError("Error Setting Theme", response.responseText, true, null);
				this.$store.commit("stopLoading");
				// Restore the old value.
				this.user["theme"] = old_theme;
				try{document.getElementById("theme-css").href = this.api_server + "/assets/css/" + this.user["active_account_id"] + "/" + this.user["theme"];}catch(e){/**/}
			})
		},
		notificationGo(notification_id, link)
		{
			this.$store.commit("startLoading");

			this.CORS("PUT", "/notifications/" + notification_id, null,
			(response) =>
			{
				this.$store.commit("notifications", response);
				this.$store.commit("stopLoading");

				if(this.notifications.length == 0)
				{
					jQuery("#notification-toast").toast("hide");
				}

				if(typeof link == "string")
				{
					this.$router.push(link).catch(() => { /* ignore error */ });
				}
			},
			(response) =>
			{
				this.showError("Error", response.responseText, true, null);
				this.$store.commit("stopLoading");
			})
		},
		notificationsClear()
		{
			this.nerivon_confirm("Are you sure?", "All of your notifications will be cleared.", "warning", true, (confirmed) =>
			{
				if(confirmed)
				{
					this.$store.commit("startLoading");

					this.CORS("DELETE", "/notifications", null,
					(response) =>
					{
						this.$store.commit("notifications", response);
						this.$store.commit("stopLoading");
					},
					(response) =>
					{
						this.showError("Error", response.responseText, true, null);
						this.$store.commit("stopLoading");
					})
				}
			});
		},
		callStatus(call)
		{
			this.current_call = call;
		},
		transferDialog()
		{
			jQuery("#call-toast").toast("hide");
			jQuery("#lead-toast").toast("hide");
			jQuery("#triage-modal").modal("hide");
			jQuery("#transfer-modal").modal({show: true, focus: true, keyboard: false});
		},
		transferClose()
		{
			jQuery("#transfer-modal").modal("hide");
		},
		setUserNumber()
		{
			for(let i=0; i<this.users.length; i++)
			{
				if(this.users[i].user_id == this.tx.user_id)
				{
					this.tx.phone = this.phoneDisplay(this.users[i].phone);
					return;
				}
			}

			this.tx.phone = "";
		},
		transferCall(sip_call_id, callback)
		{
			this.transferClose();

			if(this.nerivon_confirm("Are you sure?", "This call will be TRANSFERRED to " + this.phoneDisplay(this.tx.phone) + ".", "warning", true, (c1) =>
			{
				if(c1)
				{
					this.$store.commit("startLoading");

					this.CORS("PUT", "/calls/" + sip_call_id, JSON.stringify({"to_number": this.tx.phone}),
					() =>
					{
						if(typeof callback == "function")
						{
							callback();
						}

						this.$nextTick(() => { this.transferClose(); });
						this.$store.commit("stopLoading");
					},
					(response) =>
					{
						this.transferClose();
						this.showError("Error Transferring Call", response.responseText, true, () => {
							this.$nextTick(() => { this.transferDialog(); });
						});
						this.$store.commit("stopLoading");
					});
				}
			}));
		},
		pullQ()
		{
			// Make sure the user is available and receiving leads.
			let go = true;

			if(!this.user.receiving_leads)
			{
				// Toggle field will pull a lead automatically.
				this.toggleField("receiving_leads");
				go = false;
			}
			if(!this.user.available)
			{
				// Toggle field will pull a lead automatically.
				this.toggleField("available");
				go = false;
			}

			// If we didn't pull a lead by becoming available, pull one.
			if(go)
			{
				this.CORS("GET", "/leads/pull", null, null, null);
			}
		},
		switchAccount(account_id)
		{
			this.$store.commit("startLoading");

			this.CORS("GET", "/user_account_switch/" + account_id, null,
			() =>
			{
				this.$store.commit("stopLoading");
				window.location.reload();
			},
			(response) =>
			{
				this.showError("Error Switching Account", response.responseText, true, null);
				this.$store.commit("stopLoading");
			});
		},
		versionCheck(server_version)
		{
			this.server_version = server_version;
		},
		urlExists(url)
		{
			var http = new XMLHttpRequest();
			http.open('HEAD', "/" + url, false);
			http.send();

			return (http.status != 404)
		}
	},
	mounted()
	{
		// Create a global timer, for updating durations on-the-fly.
		setInterval(() =>
		{
			this.$store.commit("current_time");
			this.idle_timer++;

			// If the user is idle for 5 minutes, mark them idle.
			if(this.idle_timer >= 300 && !this.user.idle)
			{
				this.idle();
			}
		}, 1000);

		// Reset our idle timer on any window event.
		window.addEventListener("mousemove", () =>
		{
			this.idle_timer = 0;

			// If the user is idle, unmark them from idle.
			if(this.user.idle)
			{
				this.notIdle();
			}
		});
		window.addEventListener("keypress", () =>
		{
			this.idle_timer = 0;

			// If the user is idle, unmark them from idle.
			if(this.user.idle)
			{
				this.notIdle();
			}
		});

		// Check the user's session, which also gives us the user's information.
		// this.checkSession();
	},
	watch:
	{
		'$route' (to) {
			// Close the side menu.
			this.closeMenu();
			// Stop screens from reloading.
			this.$store.commit("stopListening", {"event": "reload_calls"});
			this.$store.commit("stopListening", {"event": "new_lead"});

			// Don't check session status on the logout page.
			if(to.name == "Logout")
			{
				return;
			}

			// Check if the user's session is still good.
			this.checkSession();
		},
		'user.active_account_id' (to, from) {
			if(to == from || to == null || to == "")
			{
				return;
			}

			try{document.getElementById("theme-css").href = this.api_server + "/assets/css/" + this.user["active_account_id"] + "/" + this.user["theme"];}catch(e){/**/}
			try{document.getElementById("favicon").href = this.api_server + "/assets/favicon/" + this.user["active_account_id"];}catch(e){/**/}
		}
	}
}
</script>
